
import { Component, Vue } from 'vue-property-decorator'
import { siteLinkUrls } from '@/api/wikidata'
import { i18n } from '@/i18n'

@Component
export default class About extends Vue {
  private sitelinks: {[key: string]: string} | null = null

  private created() {
    siteLinkUrls('Q128257').then((urls) => { // Cephalopods
      this.sitelinks = urls
    })
  }

  private get kbsLink() {
    if (i18n.locale === 'de') {
      return 'https://wbs.inf.tu-dresden.de'
    }

    return 'https://kbs.inf.tu-dresden.de'
  }

  private get squidLink() {
    if (this.sitelinks === null) {
      return 'https://en.wikipedia.org/wiki/Cephalopoda'
    }

    const wiki = `${i18n.locale}wiki`

    return this.sitelinks[wiki]
  }
}
